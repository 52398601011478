// -----------------------------------------
// input-group and input-group-addon styles
//  note: form-groups are not required
//
@mixin input-group-button-variation($vertical-padding) {
  .input-group-btn {
    .btn {
      //margin: 0 0 $vertical-padding 0;
    }
  }
}

// default margin - no form-group required
@include input-group-button-variation(input-padding-y);

.bmd-form-group-sm {
  @include input-group-button-variation($input-padding-y-sm);
}

.bmd-form-group-lg {
  @include input-group-button-variation($input-padding-y-lg);
}

.input-group {
  // may be in or outside of form-group

  .input-group-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px 0 15px;
    background-color: transparent;
    border-color: transparent;
  }

}
