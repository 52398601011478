.pinlogin {

  .pinlogin-field {
    box-sizing: border-box;
    display: inline-block;
    padding: 0px !important;
    margin: 0 5px 0 0;
    height: 48px;
    width: 40px;
    border: 1px solid #CECECE;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    text-align: center;
    font-family: Lato;
    font-size: 16pt;
    color: rgba(29, 133, 184, 1);
    outline: none;
    background: #fff;
    transition: all .5s;

    &:nth-child(4n) {
      margin-left: 10px;
    }
  }

  .pinlogin .pinlogin-field:first-of-type {
    margin-left: 0;
  }

  .pinlogin .pinlogin-field:last-of-type {
    margin-right: 0;
  }

  /* field with focus */
  .pinlogin-field {
    &:focus {
      border-color: rgba(29, 133, 184, 1) !important;
    }
  }

  /* field that's readonly */
  .pinlogin .pinlogin-field:read-only {
    border-color: #ddd;
    color: #aaa;
    background: #f5f5f5;
    cursor: default;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  /* field that's invalid */
  .pinlogin .pinlogin-field.invalid {
    border-color: #a3195b;
    color: #a3195b ;

    -webkit-box-shadow: 0px 0px 5px 0px rgba(165, 25, 91, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(165, 25, 91, 1);
    box-shadow: 0px 0px 5px 0px rgba(165, 25, 91, 1);
  }
}
