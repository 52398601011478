//https://www.google.com/design/spec/components/cards.html#cards-content-blocks
// Card resting elevation: 2dp

@import "../core/cards";

.card-blog{
    margin-top: 60px;

    [class*="col-"]{
        .card-header-image{
            img{
                width: 100%;
            }
        }
    }
    .carf-footer{
        .stats{
            .material-icons{
                font-size: 18px;
                position: relative;
                top: 4px;
                width: 19px;
            }
        }
    }
}

.card-testimonial{
    .card-footer{
        margin-top: 0;
        display: block;
        .card-avatar{
            margin-top: 10px;
            margin-bottom: -60px;
        }
    }
}

.card-contact{
    .card-header{
        margin: 0 15px;
        margin-top: -20px;
    }
}

.card-profile,
.card-testimonial{
    margin-top: 30px;
    text-align: center;


    .card-avatar{
        max-width: 130px;
        max-height: 130px;
        margin: -50px auto 0;
        border-radius: 50%;
        overflow: hidden;
        padding: 0;

        @include shadow-big();

        & + .card-body{
            margin-top: 15px;
        }
        img{
            width: 100%;
            height: auto;
        }
    }

    .card-body + .card-footer{
        margin-top: -15px;
    }

    .card-footer{
        .btn.btn-just-icon{
            font-size: 20px;
            padding: 12px 12px;
            line-height: 1em;
        }
    }

    &.card-plain{
        .card-avatar{
            margin-top: 0;
        }
    }
}

.card-testimonial{
    .card-avatar{
        max-width: 100px;
        max-height: 100px;
    }
    .card-footer{
        margin-top: 0;

        .card-avatar{
            margin-top: 10px;
            margin-bottom: -50px;
        }
    }
    .card-description{
        font-style: italic;

        & + .card-title{
            margin-top: 30px;
        }
    }
    .icon{
        margin-top: 30px;

        .material-icons{
            font-size: 40px;
        }
    }
}
.card-product{
    margin-top: 30px;

    .btn-simple.btn-just-icon{
        padding: 0;
    }

    .card-footer{
        .price{
            h4{
                margin-bottom: 0;
            }
        }
        .btn{
            margin: 0;
        }
    }

    .card-title,
    .card-category,
    .card-description{
        text-align: center;
    }
    .category{
        margin-bottom: 0;
    }
    .category ~ .card-title{
        margin-top: 0;
    }

    .price{
        font-size: 18px;
        color: #9A9A9A;
    }
    .price-old{
        text-decoration: line-through;
        font-size: 16px;
        color: #9A9A9A;
    }
    .price-new{
        color: $brand-danger;
    }
}

.card-pricing{
    &:after{
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    text-align: center;

    .card-title{
        margin-top: 30px;
    }
    .card-body{
        padding: 15px !important;
        margin: 0 !important;
    }

    .icon{
        padding: 10px 0 0px;

        i{
            font-size: 55px;
            border: 1px solid #E5E5E5;
            border-radius: 50%;
            width: 130px;
            line-height: 130px;
            height: 130px;
        }
    }

    h1{
        small{
            font-size: 18px;
            display: inline-flex;
            height: 0;

            &:first-child{
                position: relative;
                top: -17px;
                font-size: 26px;
            }
        }
    }


    ul{
        list-style: none;
        padding: 0;
        max-width: 240px;
        margin: 10px auto;

        li{
            color: $gray-color;
            text-align: center;
            padding: 12px 0;
            border-bottom: 1px solid rgba($gray-color,.3);

            &:last-child{
                border: 0;
            }
            b{
                color: $black-color;
            }
            i{
                top: 6px;
                position: relative;
            }
        }
    }

    &.card-background,
    &[class*="bg-"]{
        ul{
            li{
                color: $white-color;
                border-color: rgba($white-color,.3);

                b{
                    color: $white-color;
                    font-weight: 700;
                }
            }
        }
        [class*="text-"],
        .card-category{
            color: $white-color !important;
        }
    }

    .card-footer{
        z-index: 2;
    }
}


.card-collapse,
.card-collapse .card-header{
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
}

.card-collapse{
    margin: 0;

    & .card-header{
        border-bottom: 1px solid #ddd;
        padding: 25px 10px 5px 0px;
        margin: 0;
        box-shadow: none !important;
        background: $white;

        a{
            color: $black-color;
            font-size: 0.9375rem;
            font-weight: bolder;
            display: block;

            &:hover,
            &:active,
            &[aria-expanded="true"]{
                color: $brand-primary;
            }

            i{
                float: right;
                top: 4px;
                position: relative;
            }

            &[aria-expanded="true"] i{
                @include rotate-180();
            }
        }
    }

    .card-body{
        padding: 15px 0px 5px;
    }
}

.card-raised{
    @include shadow-big();
}


.card-signup{
    .card-header{
        // @include shadow-big();
        margin-left: 20px;
        margin-right: 20px;
        margin-top: -40px;
        padding: 20px 0;
        width: 100%;
        margin-bottom: 15px;
    }
    .text-divider{
        margin-top: 30px;
        margin-bottom: 0px;
        text-align: center;
    }
    .card-body{
        padding: 0px 30px 0px 10px;
    }

    .form-check{
        padding-top: 27px;

        label{
            margin-left: 18px;
        }
        .form-check-sign{
            padding-right: 17px;
        }
    }

    .social-line{
        margin-top: $bmd-label-top-margin-base;
        text-align: center;
        padding: 0;

        .btn{
            color: $white-color;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.card-form-horizontal{
    .card-body{
        padding-left: 15px;
        padding-right: 15px;
    }
    .form-group,
    .input-group{

        .form-control{
            margin-bottom: 0;
        }
    }

    form{
        margin: 0;
    }

    .btn{
        margin: 0;
    }
    .input-group .input-group-addon{
        padding-left: 0;
    }

    .bmd-form-group{
        padding-bottom: 0px;
        padding-top: 0;
    }
}


.card-background,
.back-background,
.front-background{
    background-position: center center;
    background-size: cover;
    text-align: center;

    .card-body{
        position: relative;
        z-index: 2;
        min-height: 280px;
        padding-top: 40px;
        padding-bottom: 40px;
        max-width: 440px;
        margin: 0 auto;
    }

    .card-category,
    .card-description,
    small{
        color: rgba($white, .7) !important;
    }

    .card-title{
        color: $white-color;
        margin-top: 10px;
    }

    &:not(.card-pricing) .btn{
        margin-bottom: 0;
    }

    &:after{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0,0,0,.56);
        border-radius: $border-radius-large;
    }
}

.back-background{
    .card-body{
        min-height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.rotating-card-container{
    @include perspective(800px);

    .card-rotate{
        background: transparent;
        box-shadow: none;

        &:after{
            display: none;
        }
    }

    .card {
        @include transitions(.8s,$transition-bezier-rotating-card);
        @include transform-style(preserve-3d);
        position: relative;


        .back, .front {
            @include backface-visibility(hidden);
            @include shadow-2dp();
            position: absolute;
            background-color: $white-color;
            border-radius: $border-radius-large;
            top: 0;
            left: 0;
            justify-content:center;
            align-content:center;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flexbox;
            display: -o-flex;
            display: flex;

           -webkit-flex-direction: column;
           -moz-flex-direction: column;
           -ms-flex-direction: column;
           -o-flex-direction: column;
           flex-direction: column;

            .card-body{
                justify-content:center;
                align-content:center;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flexbox;
                display: -o-flex;
                display: flex;
                -webkit-flex-direction: column;
                -moz-flex-direction: column;
                -ms-flex-direction: column;
                -o-flex-direction: column;
                flex-direction: column;
            }
        }

        .front {
            z-index: 2;
            position: relative;
        }

        .back {
            @include rotateY-180();
            z-index: 5;
            text-align: center;

            width: 100%;
            height: 100%;

            &.back-background{
                &:after{
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    display: block;
                    left: 0;
                    top: 0;
                    content: "";
                    background-color: rgba(0,0,0,.56);
                    border-radius: $border-radius-large;
                }
                .card-body{
                    position: relative;
                    z-index: 2;
                }
            }
            .card-footer{
                .btn{
                    margin: 0;
                }
            }

            .card-body{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    &:not(.manual-flip):hover{
        .card{
            @include rotateY-180();
        }

    }


    &.hover.manual-flip{
        .card{
            @include rotateY-180();
        }
    }

    .card-profile &{
        .front{
            text-align: left;
        }
    }
}



/*       Fix bug for IE      */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rotating-card-container .card .front,
    .rotating-card-container .card .back{
        -ms-backface-visibility: visible;
        backface-visibility: visible;
    }

    .rotating-card-container .card .back {
        visibility: hidden;
        transition: visibility .3s cubic-bezier(0.34, 1.45, 0.7, 1);
    }
    .rotating-card-container .card .front{
        z-index: 4;
    }

    .rotating-card-container:not(.manual-flip):hover .card .back,
    .rotating-card-container.manual-flip.hover .card .back{
        z-index: 5;
        visibility: visible;
    }
}
