// Modals
$modal-content-xs-box-shadow: $bmd-shadow-24dp !default;

// Padding applied to the modal body
//$modal-inner-padding:         15px !default;
//
//$modal-title-padding:         15px !default;
//$modal-title-line-height:     $line-height-base !default;
//
//$modal-content-bg:                             #fff !default;
//$modal-content-border-color:                   rgba(0,0,0,.2) !default;
//
//$modal-backdrop-bg:           #000 !default;
$modal-backdrop-opacity: .26 !default; // .5
//$modal-header-border-color:   #e5e5e5 !default;
//$modal-footer-border-color:   $modal-header-border-color !default;
//
//$modal-lg:                    900px !default;
//$modal-md:                    600px !default;
//$modal-sm:                    300px !default;
