@import "../../../material-kit-pro/scss/core/variables/colors";

$brand-primary: #FF686B;
$brand-bg: rgba(63, 169, 169, 0.5);
$brand-danger: $red-a700;
$brand-primary-gradient-start: $brand-primary;
$brand-primary-gradient-end: $brand-primary;

$theme-colors: (
  "danger": $brand-danger
);

$mdb-font-family: 'Lato', 'Helvetica', 'Arial', sanserif;
$font-family-sans-serif: $mdb-font-family;
$mdb-input-font-size-base: 16px !default;
