/** generated from https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=latin */

@font-face
{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
  url('/static/font/roboto-v18-latin-300.woff2') format('woff2'),
  url('/static/font/roboto-v18-latin-300.woff') format('woff');
}

@font-face
{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
  url('/static/font/roboto-v18-latin-regular.woff2') format('woff2'),
  url('/static/font/roboto-v18-latin-regular.woff') format('woff');
}

@font-face
{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('/static/font/roboto-v18-latin-500.woff2') format('woff2'),
  url('/static/font/roboto-v18-latin-500.woff') format('woff');
}

@font-face
{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('/static/font/roboto-v18-latin-700.woff2') format('woff2'),
  url('/static/font/roboto-v18-latin-700.woff') format('woff');
}

/** generated from https://google-webfonts-helper.herokuapp.com/fonts/roboto-slab?subsets=latin */

@font-face
{
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
  url('/static/font/roboto-slab-v7-latin-regular.woff2') format('woff2'),
  url('/static/font/roboto-slab-v7-latin-regular.woff') format('woff');
}

@font-face
{
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
  url('/static/font/roboto-slab-v7-latin-700.woff2') format('woff2'),
  url('/static/font/roboto-slab-v7-latin-700.woff') format('woff');
}

/** generated from https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin */

@font-face
{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('/static/font/open-sans-v15-latin-regular.woff2') format('woff2'),
  url('/static/font/open-sans-v15-latin-regular.woff') format('woff');
}

@font-face
{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('/static/font/open-sans-v15-latin-800.woff2') format('woff2'),
  url('/static/font/open-sans-v15-latin-800.woff') format('woff');
}


/** generated from http://google.github.io/material-design-icons/#icon-font-for-the-web */

@font-face
{
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"),
  local("MaterialIcons-Regular"),
  url("/static/font/MaterialIcons-Regular.woff2?v=1") format('woff2'),
  url("/static/font/MaterialIcons-Regular.woff?v=1") format('woff')
}

/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('/static/font/lato-v16-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Lato Hairline'), local('Lato-Hairline'),
  url('/static/font/lato-v16-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-100.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('/static/font/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
  url('/static/font/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('/static/font/lato-v16-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
  url('/static/font/lato-v16-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('/static/font/lato-v16-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
  url('/static/font/lato-v16-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-100italic.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/static/font/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
  url('/static/font/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('/static/font/lato-v16-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Italic'), local('Lato-Italic'),
  url('/static/font/lato-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/static/font/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
  url('/static/font/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('/static/font/lato-v16-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'),
  url('/static/font/lato-v16-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-900.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('/static/font/lato-v16-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
  url('/static/font/lato-v16-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('/static/font/lato-v16-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Black Italic'), local('Lato-BlackItalic'),
  url('/static/font/lato-v16-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/static/font/lato-v16-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lato-v16-latin-900italic.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lato-v16-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lato-v16-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
}

.material-icons
{
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}