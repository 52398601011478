
.bootstrap-select.form-control
{
  margin: 0px;
  height: auto;
}

.filter-option,
.dropdown-toggle::after{
  color: #000;
}

.bootstrap-select.form-control button,
.bootstrap-select.form-control button:active,
.bootstrap-select.form-control button:hover,
.bootstrap-select.form-control.show button
{
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  text-transform: none;
  font-size: inherit;
}

.bootstrap-select
{
  //border: 1px solid $brand-primary !important;

  //.select-with-transition,
  //.btn:active,
  //.btn.active
  //{
  //  background-image: linear-gradient(to top, $brand-primary 2px,rgba(0,96,169,0) 2px),linear-gradient(to top,#d2d2d2 1px,rgba(210,210,210,0) 1px);
  //}
  //
  //&.show .select-with-transition{
  //  &:focus,
  //  .is-focused &
  //  {
  //    background-size: 100% 100%, 100% 100%;
  //    transition-duration: 0.3s !important;
  //  }
  //}

  .hidden
  {
    display: none;
  }

  .dropdown-menu
  {
    max-width: 100vw;
  }
}