body {
  background-color: #FFFFFF;
}

a {
  &:focus {
    outline: none; // remove the terrible @include tab-focus();
  }
}

button:focus {
  outline: none;
  //outline: 1px dotted;
  //outline: 5px auto -webkit-focus-ring-color;
}

//// Prevent highlight on mobile
//* {
//  -webkit-tap-highlight-color: rgba($white, 0);
//  -webkit-tap-highlight-color: transparent;
//  &:focus {
//    outline: 0;
//  }
//}
