@keyframes autofill {
  to {
    color: $brand-primary;
    background: transparent;
  }
}

@keyframes loading {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.card {

  form {
    background: #ffffff;
    box-shadow: 0 3px 24px 0 rgba(0,0,0, 0.16);
    border-radius: 9px;
  }

  .card-header-primary {
    color: #000000;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    border-radius: 0;
    padding-top: 0.9375rem;
    padding-right: 2.875rem;
    padding-bottom: 0rem;
    padding-left: 2.875rem;
    background: none;
    box-shadow: none;
    -o-box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;

    h4 {
      font-family: "Golos Text", sans-serif;
      font-weight: Bold;
      font-size: 26px;
      letter-spacing: 0;
      color: $brand-primary;
      opacity: 1;
      float: left;
    }

  }

  .card-body {
    padding: 0 2.875rem;
    font-family: "Golos Text", sans-serif;
    font-weight: 400;
    font-size: 16px;

    .form-section {
      min-height: 200px;
    }

    .form-buttons {
      padding-bottom: 25px;
    }

    .qrcode {
      padding: 0 0 10px 0;
    }

    .alert-dark {
      color: $brand-primary;
      background-color: #EDEDED;
      border: 1px solid #DDDDDD;
      border-radius: .25rem;
      font-size: 16px;
      padding: 10px 15px;

      i {
        font-weight: lighter;
        font-size: 12px;
        margin-right: 5px;

        &.fa-mobile-alt {
          float: left;
          font-size: 30px;
          padding: 5px 10px 5px 0;
        }
      }
    }

    .pincode-input-container.touch input {
      width: 100%;
      border: 0;
      border-bottom: 1px solid rgba(0,0,0, 0.28);
      border-radius: 0px;
      margin-bottom: 15px;
      text-align: left;
    }

    .pincode-input-container {

      width: 100%;
      float: left;

      input {
        float: left;
        margin: 0 5px 0 0;
        padding-left: 0 !important;
        border: 1px solid #CECECE;
        border-radius: 8px;
        height: 48px;
        width: 40px;
        text-align: center;
        font-size: 20px;
        font-family: "Golos Text", sans-serif;

        &:focus {
          border-color: #1D71B8;
        }

        &:nth-child(4n) {
          margin-right: 10px;
        }
      }
    }

    .field-icon {
      float: right;
      margin-left: -25px;
      margin-top: -25px;
      position: relative;
      z-index: 2;
      color: #9B9B9B;

      &:hover {
        cursor: pointer;
      }
    }

    .progressbar {
      height: 6px;
      width: 100%;
      background-color: #DCDCDC;

      .in {
        animation: fill 30s linear infinite;
        height: 100%;
        background-color: #44A182;
      }
    }

    @keyframes fill {
      0% {
        width: 100%;
      }
      100% {
        width: 0%;
      }
    }
  }

  .card-footer {
    background: $brand-bg 0% 0% no-repeat padding-box;
    border-radius: 0 0 7px 7px;
    padding: .9375rem 2.875rem;
    font-size: 16px;
    width: 100%;


    label {
      color: #000000;
      font-size: 16px;

      a {
        color: #1D71B8;
        text-decoration: underline;
        font-size: 16px;

        &:hover {
          color: #A3195B;
          text-decoration: underline;
        }
      }
    }
  }

  .btn-primary:hover {
    color: $brand-primary;
    background-color: #FFFFFF;
    box-shadow: none;
    border: 1px solid $brand-primary;
  }

  .btn-primary {
    color: #ffffff;
    background: $brand-primary 0% 0% no-repeat padding-box;
    text-transform: none;
    font-family: "Golos Text", sans-serif;
    font-size: 16px;
    border-radius: 32px;

    &.btn-link {
      color: #1D71B8;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        color: #A3195B;
        text-decoration: underline;
        background-color: transparent;
        border: none;
      }
    }
  }

  .btn-link {
    &:hover,
    &:focus,
    &:active {
      color: #A3195B;
      text-decoration: underline !important;
      background-color: transparent;
    }
  }

  .external-login-options {
    display: grid;
    grid-template-columns: 37% 63%;
    align-items: start;
    gap: 5px;
  }

  .login-azure {
    border: 1px solid #dadce0;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 3px;
    margin-top: 5px;

    color: #3c4043;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;

    &:active {
      background-color: rgba(236, 242, 254, 0.6);
    }
    &:hover {
      background-color: rgba(236, 242, 254, 0.6);
      transition:0.3s;
    }
  }

  .login-google {
    margin-top: 5px;
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #000000;
    font-size: 16px;
  }
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px white inset;
    -webkit-text-fill-color: #000000;
  }

  input, .bootstrap-select {
    color: #000000 !important;
    border-bottom: 1px solid rgba(0,0,0, 0.28);
    background: transparent !important;
    background-image: none !important;
  }

  input, .bootstrap-select > button {
    padding-left: 8px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .bootstrap-select.form-control button,
  .bootstrap-select.form-control button:active,
  .bootstrap-select.form-control button:hover,
  .bootstrap-select.form-control.show button {
    background: transparent !important;
  }

  .bs-searchbox input {
    color: $brand-bg !important;
  }

  .bmd-form-group {
    padding-top: 0;
  }

  .form-group {
    margin-top: 17px;
  }

  form .form-invalid-feedback {
    display: none;
    text-align: center;
    color: white;
    padding: 5px;
    font-weight: bold;
  }

  form .form-invalid-visible {
    display: block;
  }

  form.is-invalid .form-invalid-feedback {
    display: block;
  }

  form.loading .card-separator {
    animation: loading 5s ease infinite;
  }

  .text-muted {
    color: #888 !important;
    font-size: small;
  }

  .change-language-select {
    width: 60px;
    float: right;
  }

  select.change-language {
    color: $brand-primary;
  }

  .change-language {
    margin-bottom: 10px;
    margin-top: 10px;
    background: none;
    font-weight: bold;
    font-size: 16px;

    &:active,
    &:focus {
      outline: none;
    }

    option {
      padding-right: 21px;
    }
  }

  .change-language + i.fas {
    margin-top: -35px;
    margin-right: 10px;
    pointer-events: none;
    background-color: transparent;
    color: $brand-primary !important;
    position: absolute;
    right: 0px;
  }

  background: none;
  box-shadow: none;
  border-radius: 0;
  opacity: 1;
  color: #000000;
}

.card .text-muted {
  font-size: 16px;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  font-size: 16px;
}

body {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  font-size: 16px;
}

.page-header .logo {
  display: none;
  margin: 20px 0;
}

.is-focused .form-control {
  border-color: rgba(0,0,0,.28);
}

@include media-breakpoint-down(sm) {

  .container {
    padding: 0;
  }

  .page-header {
    align-items: unset;
  }

  .page-header .logo {
    margin: 20px;
  }

  .card {
    height: 100vh;
    margin: 0;
  }

  .card form {
    border-radius: 0;
    margin: 0;
    height: 100%;
  }
  .card .card-body,
  .card .card-footer,
  .card .card-header-primary {
    padding: 10px 20px;
    border-radius: 0;
  }

  .card .card-header-primary h4 {
    font-size: 22px;
  }

  .card .card-body .form-section {
    min-height: auto;
  }

  .row {
    margin: 0;
  }

  .row .col-sm-12 {
    padding: 0;
  }

}
