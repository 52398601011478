// List group

$list-group-bg: inherit !default; // #fff
//$list-group-border-color:       #ddd !default;
$list-group-border-width: 0 !default; // $border-width
$list-group-border-radius: 0 !default; // $border-radius
//
//$list-group-hover-bg:           #f5f5f5 !default;
//$list-group-active-color:       $component-active-color !default;
//$list-group-active-bg:          $component-active-bg !default;
//$list-group-active-border:      $list-group-active-bg !default;
//$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;
//
//$list-group-disabled-color:      $gray-light !default;
//$list-group-disabled-bg:         $gray-lighter !default;
//$list-group-disabled-text-color: $list-group-disabled-color !default;
//
//$list-group-link-color:         #555 !default;
//$list-group-link-hover-color:   $list-group-link-color !default;
//$list-group-link-heading-color: #333 !default;
