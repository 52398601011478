.form-control
{
  box-shadow: none !important;
}

.form-group.is-invalid > .invalid-feedback
{
  display: block;
  position: absolute;
}

.shake
{
  animation: shake 0.5s;
}

@keyframes shake
{
  10%, 90%
  {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80%
  {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70%
  {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60%
  {
    transform: translate3d(4px, 0, 0);
  }
}

.form-group[data-form-icon]
{
  margin-left: 40px;

  &.is-invalid::before
  {
    color: $brand-danger;
  }

  &.is-focused::before
  {
    color: $brand-primary;
  }

  &:before
  {
    content: attr(data-form-icon);
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
    position: absolute;
    padding: $input-padding-y $input-padding-x;
    margin-left: -40px;
    color: #9B9B9B;
  }
}

form:empty
{
  display: none;
}



.form-check-image
{
  .form-check-input + img
  {
    margin: 10px;
    padding: 10px;
    pointer-events: none;
  }

  .form-check-input:checked + img
  {
    @include border-radius($nav-pills-border-radius);
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

.qrcode
{
  width: 100%;
  background-color: white;
  padding: 10px;

  img
  {
    width: 100%;
  }
}

.page-login form
{
  .progress
  {
    visibility: hidden;
    margin-bottom: 0;
  }

  &.loading
  {
    .progress
    {
      visibility: visible;
    }
  }
}

